<!-- eslint-disable max-len -->
<template>
  <div class="contact-form londo-form relative">
    <div
      :ref="sharpspringForm ? sharpspringForm.formId : null"
      v-show="submitting !== 'submitted'"
    >
      <div class="londo-form__title h3" v-if="heading">
        {{ heading }}
      </div>

      <component
        autocomplete="on"
        novalidate
        :ref="sharpspringForm && renderFormTag ? sharpspringFormRef : null"
        :is="renderFormTag ? 'form' : 'div'"
        :id="renderFormTag && sharpspringForm.formId"
        @submit="
          contactStateScope == 'newsletter'
            ? onFormSubmit($event, {
                event: 'newsletter_sign_up',
                subscription_type: [monthly && 'monthly', daily && 'daily']
                  .filter((a) => a)
                  .join(', '),
              })
            : onFormSubmit($event, { event: 'submit_contact_form' })
        "
      >
        <input
          v-if="formTypeHiddenValue"
          type="hidden"
          name="form-type-interested-in"
          :value="formTypeHiddenValue"
        />

        <LondoInput
          label="first name"
          inputName="given-name"
          :inputId="contactStateScope + '-enquiry-given-name'"
          @focus="onInputClick('given-name')"
          v-model="firstName"
        />

        <LondoInput
          label="last name"
          inputName="family-name"
          autocomplete="family-name"
          :inputId="contactStateScope + '-enquiry-last-name'"
          @focus="onInputClick('family-name')"
          v-model="lastName"
        />

        <LondoInput
          label="company name"
          autocomplete="organisation"
          v-if="isAgentEnquiry"
          v-model="companyName"
          inputName="company-name"
          @focus="onInputClick('companyName')"
          :inputId="contactStateScope + '-enquiry-company-name'"
          :error="companyNameError"
        />

        <LondoInput
          label="email"
          autocomplete="email"
          type="email"
          v-model="email"
          inputName="email"
          @focus="onInputClick('email')"
          :inputId="contactStateScope + '-enquiry-email'"
          :error="emailError"
        />

        <LondoInput
          label="mobile"
          autocomplete="tel"
          inputName="contact-number"
          :isPhone="true"
          :inputId="contactStateScope + '-enquiry-tel'"
          v-if="contactNumber !== undefined"
          @focus="onInputClick('contact-number')"
          v-model="contactNumber"
        />

        <LondoInput
          v-if="message !== undefined"
          v-model="message"
          inputName="message"
          placeholder="Ask us any questions you might have"
          label="your message"
          type="textarea"
          :multiline="true"
          :inputId="contactStateScope + '-enquiry-message'"
          @click="onInputClick('message')"
        />

        <div
          v-if="contactState.daily !== undefined"
          class="sub-options londo-checkbox-group"
        >
          <label class="input-label input-label-checkbox">
            <input
              type="checkbox"
              v-model="daily"
              class="input-checkbox"
              name="daily-subscribe"
              @click="onInputClick('daily-subscribe')"
            />
            Daily Story
          </label>

          <label class="input-label input-label-checkbox">
            <input
              type="checkbox"
              v-model="monthly"
              class="input-checkbox"
              name="monthly-subscribe"
              @click="onInputClick('monthly-subscribe')"
            />
            Monthly Newsletter
          </label>
        </div>

        <div v-else class="londo-switch-group">
          <LondoSwitch
            v-model="privacyAccepted"
            fieldName="terms-and-conditions"
          />
          <label class="londo-switch-group__label">
            I have read and understood the
            <a
              target="_blank"
              rel="noreferrer"
              href="/en/contact/privacy-policy"
              class="pl-1 text-blue"
              >Privacy Policy</a
            >
          </label>
        </div>
        <span
          class="button-tooltip"
          v-tooltip="{
            content: 'Please complete fields',
            disabled: isValid,
          }"
        >
          <button
            v-if="showSubmit"
            :disabled="!isValid"
            class="btn btn-default btn-submit"
          >
            {{ submitText }}
          </button>
        </span>
      </component>
      <div v-if="renderFormTag" ref="script-placeholder"></div>
    </div>

    <div v-if="renderFormTag">
      <transition name="fade" v-if="submitting === 'submitting'">
        <div class="form-loading">
          <span>One moment...</span>
        </div>
      </transition>

      <transition name="fade" v-if="submitting === 'submitted'">
        <div>
          <div class="h3 mt-0">Thank You</div>

          <div class="alert alert--success">
            <p>{{ successMessage }}</p>
          </div>

          <button class="btn btn-default w-full" @click="clearFilters">
            Done
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
/* eslint-disable space-before-function-paren */
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import LondoInput from "@/components/Forms/Input.vue";
import enquiryActions from "@/store/modules/enquiry-mutation-types";
import LondoSwitch from "@/components/Forms/Switch.vue";
import forms from "@/mixins/forms";
import { defaultFilters } from "@/store/modules/enquiry";

export default {
  name: "LondoContactDetailForm",
  props: {
    contactState: {
      type: [Object],
      required: true,
    },
    contactStateScope: {
      type: String,
      required: true,
    },
    showSubmit: {
      type: Boolean,
      default: true,
    },
    submitText: {
      type: String,
      default: "Submit",
    },
    heading: {
      type: [String, Boolean],
      default: false,
    },
    sharpspringForm: {
      type: [Object],
    },
    renderFormTag: {
      type: Boolean,
      default: true,
    },
    formTypeHiddenValue: {
      type: String,
    },
    successMessage: {
      default:
        "Your enquiry has been sent. One of our team members will make contact with you shortly",
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  mixins: [forms],
  data() {
    return {
      actionMapping: enquiryActions,
      isValid: false,
    };
  },
  components: {
    LondoInput,
    LondoSwitch,
  },
  methods: {
    clearFilters() {
      this.$store.commit(this.actionMapping.reset, {
        scope: this.contactStateScope,
        filters: defaultFilters[this.contactStateScope],
      });
      this.submitting = null;
    },
    onInputClick(fieldName) {
      this.$emit("fieldClicked", fieldName);
    },
    setStoreValid: function () {
      let newValid = !this.v$.$invalid;
      if (this.contactStateScope === "retreat") {
        newValid = newValid && this.privacyAccepted;
      }
      this.isValid = newValid;
      const action = this.actionMapping[this.contactStateScope].contact.valid;
      if (action) {
        this.$store.commit(action, newValid);
      }
    },
  },
  computed: {
    sharpspringFormRef() {
      return `${this.sharpspringForm.formId}-form`;
    },
    isAgentEnquiry() {
      if (!this.$route.params?.slug) {
        return false;
      }
      return this.$route.params.slug === "agent-zone";
    },
    firstName: {
      get() {
        return this.contactState.firstName;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.contactStateScope].contact.firstName,
          value
        );
        this.v$.firstName.$touch();
        this.setStoreValid();
      },
    },
    lastName: {
      get() {
        return this.contactState.lastName;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.contactStateScope].contact.lastName,
          value
        );
        this.v$.lastName.$touch();
        this.setStoreValid();
      },
    },
    companyName: {
      get() {
        return this.contactState.companyName;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.contactStateScope].contact.companyName,
          value
        );
      },
    },
    email: {
      get() {
        return this.contactState.email;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.contactStateScope].contact.email,
          value
        );
        this.v$.email.$touch();
        this.setStoreValid();
      },
    },
    contactNumber: {
      get() {
        return this.contactState.contactNumber;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.contactStateScope].contact.contactNumber,
          value
        );
      },
    },
    message: {
      get() {
        return this.contactState.message;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.contactStateScope].contact.message,
          value
        );
      },
    },
    daily: {
      get() {
        return this.contactState.daily;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.contactStateScope].contact.daily,
          value
        );
      },
    },
    monthly: {
      get() {
        return this.contactState.monthly;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.contactStateScope].contact.monthly,
          value
        );
      },
    },
    privacyAccepted: {
      get() {
        return this.contactState.privacyAccepted;
      },
      set(value) {
        this.$store.commit(
          this.actionMapping[this.contactStateScope].contact.privacyAccepted,
          value
        );
        this.onInputClick('privacy-accept');
        this.setStoreValid();
      },
    },
    emailError() {
      if (this.v$.email?.$dirty && this.v$.email?.$invalid) {
        return "Please provide a valid email address";
      }

      return "";
    },
  },
  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    email: {
      required,
      email,
    },
  },
};
</script>

<style lang="scss">
.contact-form {
  .terms {
    margin-top: 1rem;
  }
  .button {
    margin: 1rem 0;
    width: 100%;
    span {
      width: 100%;
      text-align: center;
    }
  }
}
</style>
