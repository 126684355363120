/* eslint-disable camelcase, no-underscore-dangle */
import makeRhinoRequest from "@/util/rhino";
import enquiryActions from "@/store/modules/enquiry-mutation-types";
import trackEvent from "@/mixins/tracking";
import { CONSTANTS } from "@/plugins/constants";

const GTAG_ID = "GTM-WNMV6H";

export default {
  data() {
    return {
      submitting: null,
      actionMapping: enquiryActions,
      bypassActualSubmission: false,
    };
  },

  mixins: [trackEvent],

  mounted() {
    this.prepareSharpspring();
  },

  methods: {
    prepareSharpspring() {
      if (!process.client) {
        return;
      }

      const scriptPlaceholder = this.$refs["script-placeholder"];

      if (scriptPlaceholder && this.sharpspringForm) {
        const script = document.createElement("script");
        script.src = CONSTANTS.sharpspring.scriptSrc;
        script.defer = true;

        this.$refs[this.sharpspringForm.formId].replaceChild(
          script,
          scriptPlaceholder
        );

        window.__ss_noform = window.__ss_noform || [];
        window.__ss_noform.push(["baseURI", CONSTANTS.sharpspring.baseURI]);
        window.__ss_noform.push([
          "form",
          this.sharpspringForm.formId,
          this.sharpspringForm.endpoint,
        ]);
        window.__ss_noform.push(["submitType", "manual"]);
      }
    },

    appendFormSubmissionTrackersToForm() {
      if (!process.client) {
        return;
      }

      //const { vid } = window.gaGlobal;
      const form = this.$refs[`${this.sharpspringForm.formId}-form`];

      if (form) {
        //this.appendHiddenFormFieldToForm("cid", vid);
        this.appendHiddenFormFieldToForm("tid", GTAG_ID);
      }
    },

    async onFormSubmit(e, formTrackingFields) {
      this.submitting = "submitting";

      this.appendFormSubmissionTrackersToForm();
      e.preventDefault();

      //bypass actually submission for testing
      if (this.bypassActualSubmission) {
        const timeout = 8000;
        console.log(
          "%c%s",
          "color: red;",
          `Faux submission ${timeout / 1000}s timeout`
        );

        setTimeout(() => {
          this.submitting = "submitted";
        }, timeout);

        return;
      }

      try {
        let leadId;

        const formValues = this.parseForm();

        if (this.edForm) {
          const response = await makeRhinoRequest(formValues);

          if (
            response.status === 200 &&
            response.data &&
            response.data.leadId
          ) {
            leadId = response.data.leadId;

            formValues["lead-id"] = leadId;
          }
        }

        this.sharpspringSubmission(leadId);
        this.fireAnalyticsEvent(formTrackingFields, leadId);

        const monthlySubscribe = formValues["monthly-subscribe"] === "on";
        const dailySubscribe = formValues["daily-subscribe"] === "on";

        if (
          formTrackingFields &&
          formTrackingFields.event === "submit_enquiry" &&
          (monthlySubscribe || dailySubscribe)
        ) {
          this.fireAnalyticsEvent({
            event: "newsletter_sign_up",
            subscription_type: [
              monthlySubscribe && "monthly",
              dailySubscribe && "daily",
            ]
              .filter((a) => a)
              .join(", "),
          });
        }
      } catch (error) {
        console.error("Error during form submission:", error);
      } finally {
        this.submitting = "submitted";
      }
    },

    sharpspringSubmission(leadId = null) {
      if (!process.client) return;
      if (!window.__ss_noform) {
        return;
      }

      if (leadId) {
        this.appendHiddenFormFieldToForm("lead-id", leadId);
      }

      window.__ss_noform.push(["submit", null, this.sharpspringForm.endpoint]);
    },

    fireAnalyticsEvent(formTrackingFields, leadId) {
      if (this.trackEvent) {
        this.trackEvent({
          ...formTrackingFields,
          enquiry_number: leadId,
        });
      }
    },

    appendHiddenFormFieldToForm(name, value) {
      const form = this.$refs[`${this.sharpspringForm.formId}-form`];

      if (form) {
        const hiddenInput = document.createElement("input");

        hiddenInput.setAttribute("type", "hidden");
        hiddenInput.setAttribute("name", name);
        hiddenInput.setAttribute("value", value);

        form.appendChild(hiddenInput);
      }
    },

    parseForm() {
      const form = this.$refs[`${this.sharpspringForm.formId}-form`];

      if (form) {
        const formValues = {};

        for (let i = 0; i < form.elements.length; i += 1) {
          const e = form.elements[i];
          const { name, value } = e;

          if (name && value) {
            formValues[name] = value;
          }
        }

        return formValues;
      }

      return null;
    },
  },
};
