<template>
  <div
    class="londo-input"
    :class="[{ 'londo-input--error': error }, `londo-input-${type}`]"
    :data-field="inputId"
  >
    <div class="londo-input__group">
      <label class="londo-input__group__label input-label" :for="inputId">
        {{ label }}
      </label>

      <input
        v-if="isPhone"
        :value="countryCode"
        @input="onChange"
        @blur="onBlur"
        @focus="onFocus"
        @change="onChange"
        @click="onClick"
        name="country"
        type="hidden"
      />

      <input
        v-if="!multiline"
        class="londo-input__group__input input input-input"
        :type="type"
        :id="inputId"
        :ref="inputId"
        :name="inputName"
        :readonly="readonly"
        :value="displayValue || modelValue"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        @input="onChange"
        @blur="onBlur"
        @focus="onFocus"
        @change="onChange"
        @click="onClick"
        novalidate
      />

      <textarea
        v-else
        class="londo-input__group__input input input-textarea"
        :id="inputId"
        :ref="inputId"
        :name="inputName"
        :readonly="readonly"
        :value="modelValue"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        rows="3"
        @blur="onBlur"
        @focus="onFocus"
        @input="onChange"
        @change="onChange"
        @click="onClick"
      />
    </div>

    <div class="londo-input__group__error input-error">
      <span v-if="error">
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script>
import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";

export default {
  name: "LondoInput",
  data() {
    return {
      countryCode: null,
      modelValue: this.value,
    };
  },
  props: {
    label: {
      required: true,
      type: String,
    },
    multiline: {
      required: false,
      type: Boolean,
      default: false,
    },
    readonly: {
      default: false,
      required: false,
    },
    displayValue: {
      type: String,
      required: false,
      default: null,
    },
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
    inputId: {
      type: String,
      required: false,
    },
    inputName: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    isPhone: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    error: {
      type: String,
      required: false,
    },
  },
  computed: {},
  mounted() {
    if (process.client && this.isPhone) {
      const telInput = this.$refs[this.inputId];

      if (telInput) {
        intlTelInput(telInput, {
          preferredCountries: [
            "us",
            "gb",
            "fr",
            "de",
            "za",
            "au",
            "in",
            "ca",
            "br",
            "nl",
          ],
        });

        this.countryCode = "us";

        telInput.addEventListener("countrychange", () => {
          const iti = window.intlTelInputGlobals.getInstance(telInput);
          if (iti) {
            this.countryCode = iti.getSelectedCountryData().iso2;
          }
        });
      }
    }
  },
  methods: {
    onChange(event) {
      this.$emit("input", event.target.value);
      this.$emit("update:modelValue", event.target.value);
      this.modelValue = event.target.value;
    },
    onClick(event) {
      this.$emit("clicked", event);
    },
    onBlur(event) {
      this.$emit("blur", event);
    },
    onFocus(event) {
      this.$emit("focus", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.londo-input {
  input,
  textarea {
    &::placeholder {
      @apply text-grey-300;
      opacity: 0.8;
    }
  }
  textarea {
    @apply pt-2 leading-relaxed;
  }
}
</style>
