import axios from "axios";
import dayjs from "dayjs";
import store from "@/store";

const ENDPOINT = "https://cms.londolozi.com/wp-json/londolozi-cms/v1/processEnquiry";

const bookingKeyMapping = {
  first_name: "given-name",
  last_name: "family-name",
  adults: "noadults",
  tours: "form-type-interested-in",
  arrival_date: {
    key: "arrival-date",
    formatValue: (arrivalDate) => dayjs(arrivalDate).format("YYYY-MM-DD"),
  },
  departure_date: {
    key: "departure-date",
    formatValue: (departureDate) => dayjs(departureDate).format("YYYY-MM-DD"),
  },
  clean_contact_number: "contact-number",
  comments: "extra-info-message",
  travel_agent_agency: "travel agent",
  contact_number: "contact-number",
  using_travel_agent: "travel agent",
  travel_agent: "travel agent",
  referred: "was-referred",
  previous_visitor: "been-before",
  daily_subscribe: "daily-subscribe",
  monthly_subscribe: "monthly-subscribe",
  flights_booked: {
    key: "flights_booked",
    formatValue: (bf) => bf === "on",
  },
};

const sanitizeRhinoPayload = (data) => {
  const mappedData = { ...data };

  const keysToDelete = [
    "nochildren",
    "nochildren1216",
    "arrival-date",
    "departure-date",
  ];

  Object.keys(bookingKeyMapping).forEach((key) => {
    const dataKey = bookingKeyMapping[key];
    if (dataKey) {
      if (typeof dataKey === "object") {
        mappedData[key] = dataKey.formatValue(mappedData[dataKey.key]);
      } else {
        mappedData[key] = mappedData[dataKey];
      }

      keysToDelete.push(dataKey);
    }
  });

  if (mappedData.nochildren) {
    mappedData.children =
      parseInt(mappedData.nochildren, 10) +
      parseInt(mappedData.nochildren1216, 10);

    mappedData.children_ages = [
      `${mappedData.nochildren || 0} younger than 12`,
      `${mappedData.nochildren1216 || 0} older than 12 and younger than 16`,
    ];
  }

  mappedData.arrival_date_v2 = {
    range_to: mappedData.departure_date,
    range_from: mappedData.arrival_date,
  };

  Object.keys(mappedData).forEach((key) => {
    if (mappedData[key] === "on") {
      mappedData[key] = "Yes";
    }

    if (mappedData[key] === "off") {
      mappedData[key] = "No";
    }
  });

  keysToDelete.forEach((key) => delete mappedData[key]);

  return mappedData;
};

const makeRhinoRequest = async (data) => {
  const deviceDetail = {
    user_agent: navigator.userAgent,
    referrer: document.referrer,
    browser: navigator.appVersion,
  };

  const mappedData = sanitizeRhinoPayload(data);

  const resp = await axios.post(ENDPOINT, {
    clickpath: store.state.session.clickPath.join("\n"),
    ...deviceDetail,
    ...mappedData,
  });

  return resp;
};

export default makeRhinoRequest;
