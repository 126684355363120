<template>
  <label class="switch" :for="id">
    <input
      type="checkbox"
      :name="fieldName || id"
      :checked="modelValue"
      :id="id"
      :value="modelValue ? 'on' : 'off'"
      @change="onChange"
    />
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  name: "LondoSwitch",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: false,
    },
    fieldName: {
      type: String,
    },
  },
  watch: {
    value(val) {
      this.checkboxValue = val;
    },
  },
  methods: {
    onChange() {
      this.checkboxValue = !this.checkboxValue;
      this.$emit("update:modelValue", this.checkboxValue);
    },
  },
};
</script>

<style lang="scss">
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
